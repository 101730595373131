<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <section>
          <div>
            <i18n
              path="overview.paragraph.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #link1>
                <a @click="goToTab(1)">{{ $t("overview.paragraph.link1") }}</a>
              </template>
              <template #link2>
                <a>{{ $t("overview.paragraph.link2") }}</a>
              </template>
            </i18n>
          </div>
        </section>

        <!-- ******************* SUMMARY *********************** -->
        <!-- 
        <div class="steps__subtitle bsat__title">
          {{ $t("summary.title") }}
        </div>
        <div class="bsat__paragraph">{{ $t("summary.paragraph") }}</div>

        <ul class="bsat__paragraph">
          <li v-for="(item, i) in summaryUnorderedList" :key="i" class="my-1">
            <div>
              <span>{{ item.title }}</span>
              <ul class="bsat__paragraph" v-if="item.children">
                <li
                  v-for="(child, j) in getSummaryChildren(item.children)"
                  :key="j"
                  class="my-1"
                >
                  <div v-if="!child.children">{{ child.text }}</div>
                  <div v-else>
                    {{ child.text }}
                    <ul>
                      <li
                        v-for="(child2, k) in getSummaryChildren(
                          child.children
                        )"
                        :key="k"
                        class="my-1"
                      >
                        {{ child2.text }}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="bsat__paragraph">
          <i18n
            path="summary.paragraph2.text"
            tag="div"
            class="bsat__paragraph"
          >
            <template #link>
              <a @click="goToStep(2)">{{ $t("summary.paragraph2.link") }}</a>
            </template>
          </i18n>
        </div> -->

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS 1 ********************************* -->

        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <v-row align="center" v-if="$vuetify.breakpoint.smAndUp">
              <v-col cols="12" sm="4"
                ><div class="font-weight-bold dark-text">Task</div></v-col
              >
              <v-col cols="12" sm="4"
                ><div class="font-weight-bold dark-text">
                  Task Leader
                </div></v-col
              >
              <v-col cols="12" sm="4"
                ><div class="font-weight-bold dark-text pl-2">
                  Proposed Methods
                </div></v-col
              >
            </v-row>
            <v-divider
              class="bg-dark"
              v-if="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <!-- ************************* TASK 1 ********************************************** -->
            <v-row>
              <v-col cols="12" sm="4">
                <i18n
                  path="implementationRecommendations.task1.text"
                  tag="div"
                  class="bsat__paragraph mb-4"
                >
                  <template #title>
                    <strong>{{
                      $t("implementationRecommendations.task1.title")
                    }}</strong>
                  </template>
                </i18n>
              </v-col>
              <v-col cols="12" sm="4">
                <!-- <div class="bsat__paragraph mb-4" v-if="$vuetify.breakpoint.xsOnly">
                  <strong>{{
                    $t("implementationRecommendations.task1.taskLeader.title")
                  }}</strong>
                </div> -->
                <ul>
                  <li class="bsat__paragraph">
                    {{
                      $t("implementationRecommendations.task1.taskLeader.text")
                    }}
                  </li>
                </ul>
                <!-- <i18n
                  path="implementationRecommendations.task1.taskLeader.text"
                  tag="div"
                  class="bsat__paragraph mb-4"
                >
                  <template #title>
                    <strong>{{
                      $t("implementationRecommendations.task1.taskLeader.title")
                    }}</strong>
                  </template>
                </i18n> -->
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t(
                      "implementationRecommendations.task1.proposedMethods.title"
                    )
                  }}</strong>
                </div>
                <div>
                  <ul>
                    <li
                      class="bsat__paragraph mb-4"
                      v-for="(item, i) in proposedMethodsTask1"
                      :key="i"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <v-divider
              class="bg-dark"
              v-if="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <!-- ************************* TASK 2 ********************************************** -->
            <v-row>
              <v-col cols="12" sm="4">
                <i18n
                  path="implementationRecommendations.task2.text"
                  tag="div"
                  class="bsat__paragraph mb-4"
                >
                  <template #title>
                    <strong>{{
                      $t("implementationRecommendations.task2.title")
                    }}</strong>
                  </template>
                </i18n>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t("implementationRecommendations.task2.taskLeader.title")
                  }}</strong>
                </div>
                <ul>
                  <li
                    class="bsat__paragraph mb-4"
                    v-for="(item, i) in task2LeaderList"
                    :key="i"
                  >
                    {{ item }}
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t(
                      "implementationRecommendations.task2.proposedMethods.title"
                    )
                  }}</strong>
                </div>
                <div>
                  <ul>
                    <li
                      class="bsat__paragraph mb-4"
                      v-for="(item, i) in proposedMethodsTask2"
                      :key="i"
                    >
                      <div>
                        <span>
                          {{ item.title }}
                        </span>
                        <ul class="mt-2" v-if="item.children">
                          <li
                            class="bsat__paragraph mb-2"
                            v-for="(child, i) in getChildren(item)"
                            :key="i"
                          >
                            {{ child }}
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </ReadMore>
        </section>

        <!-- ********************* RECOMMENDED RESOURCES ********************* -->
        <div class="steps__subtitle bsat__subtitle my-4">
          {{ $t("recommendedResources.title") }}
        </div>

        <!-- <div class="bsat__paragraph mt-n3 mb-6">
          {{ $t("recommendedResourcesIntro") }}
        </div> -->

        <ul class="ml-0 ml-sm-4 bsat__ul">
          <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph") }}
            </div>
          </li>

          <li>
            <i18n
              path="recommendedResources.paragraph2.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #link>
                <a @click="goToTab(1)">{{
                  $t("recommendedResources.paragraph2.link")
                }}</a>
              </template>
            </i18n>
          </li>

          <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph3") }}
            </div>
          </li>

          <li>
            <div class="bsat__paragraph">
              {{ $t("recommendedResources.paragraph4") }}
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.keyPerformance.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.keyPerformance.title")
                  }}</strong>
                </template>
              </i18n>
              <a :href="keyPerformanceFile" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.catalogue.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.catalogue.title")
                  }}</strong>
                </template>
              </i18n>

              <a
                :href="catalogueLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </a>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.bestPractices.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.bestPractices.title")
                  }}</strong>
                </template>
              </i18n>
              <router-link
                to="/best-practice-examples-of-bio-based-solutions-with-replicability-potential"
              >
                <v-btn
                  outlined
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("visit") }}</v-btn
                >
              </router-link>
            </div>
          </li>

          <li>
            <div>
              <i18n
                path="recommendedResources.regionalPolicies.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{
                    $t("recommendedResources.regionalPolicies.link")
                  }}</strong>
                </template>
              </i18n>
              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                to="/repository-of-regional-policies-to-support-bio-based-models"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </div>
          </li>
        </ul>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
	"en": {
		"implementationRecommendations": {
			"task1": {
				"proposedMethods": {
					"title": "Proposed methods",
					"unorderedList": [
						"Use reference list of most pressing challenges in bioeconomy regions. ",
						"Interviews with regional experts "
					]
				},
				"taskLeader": {
					"text": "Strategy update working group",
					"title": "Task leader"
				},
				"text": "{title}: Analyse challenges of bioeconomy at regional level ",
				"title": "Task 1"
			},
			"task2": {
				"paragraph": {
					"link": "Step 1",
					"text": "All methods to be used should have been already planned in the participatory process approach ({link}), as well as its regularity. "
				},
				"proposedMethods": {
					"title": "Proposed methods",
					"unorderedList": [
						{
							"title": "Apply policy analysis from Phase 1"
						},
						{
							"children": [
								"Sectorial focus groups ",
								"Discussion/workshop with a select-ed group of experts (Advisory group)",
								"Thematic working groups"
							],
							"title": "Revision of impact of regional bioeconomy strategy as well as necessary alignments with current EU bioeconomy policy developments (e.g. Updated EU Bioeconomy strategy, Green Deal) using: "
						}
					]
				},
				"taskLeader": {
					"title": "Task Leader",
					"unorderedList": [
						"Strategy update working group. ",
						"In close cooperation with key stakeholders engaged through the participatory process"
					]
				},
				"text": "{title}: Identify possible improvements and alignment of strategy priority areas with EU current policy updates",
				"title": "Task 2"
			},
			"title": "Implementation Recommendations"
		},
		"overview": {
			"paragraph": {
				"link1": "Phase 2",
				"link2": "Step 2",
				"text": "The region is suggested to use the results of the SAT ‘Self-Assessment Tool’ as well as the responses to the policy questionnaire from {link1} ({link2}). Based on those elements, it is recommended to initiate an analysis of the current latent bioeconomy challenges in the region. It is possible that these challenges have not been even detected during the implementation of the regional bioeconomy strategy. Thus,  it is advisable to undertake interviews with chosen bioeconomy experts that help in recognizing the major issues to consider during the strategy up-date."
			},
			"paragraph2": "Furthermore, engage a bigger group of stakeholders (experts from policy, academia, industry and civil society) when discussing possible improvements and how to go forwards in the following years with the updated strategy and roadmap. Generating consensus as much as it is possible between the stakeholders and making sure the key concerns are being hold into account. "
		},
		"recommendedResources": {
			"bestPractices": {
				"text": "{title} Consult a selection of bio-based solutions from the POWER4BIO catalogue, which thoroughly describe their potential for replicability in rural areas",
				"title": "Best practice examples:"
			},
			"catalogue": {
				"link": "https://www.bio-based-solutions.eu/#/",
				"text": "{title} Get inspired by the POWER4BIO catalogue of bio-based solutions about key enabling aspects to develop and/or good practices to deploy at regional level.",
				"title": "Catalogue of bio-based solutions:"
			},
			"keyPerformance": {
				"text": "{title} The use of common indicators could reinforce the common understanding about the bioeconomy topic as well as frame the scope of the exercise. The POWER4BIO has agreed to use 7 simple indicators resulted from a through desk research analysis. The following document could be an inspiring reference. It includes  61 indicators clustered in 7 simple to use categories.",
				"title": "Key performance indicators: "
			},
			"paragraph": "Current regional bioeconomy strategy vision",
			"paragraph2": {
				"link": "Phase 1",
				"text": "Results of the SAT analysis and policy analysis ({link})"
			},
			"paragraph3": "Template for interviews with regional experts",
			"paragraph4": "Results of applying the existing KPIs in your region against the current regional bioeconomy strategy status",
			"regionalPolicies": {
				"link": "Repository of Supporting documents and tools for regional policies in the field of bioeconomy:",
				"text": "{link} Identify areas of priority, based on supporting policies thoroughly described and good examples . Policy information from the bioeconomy field at this stage, from a helicopter view, will help the region assess the general elements to bear in mind in the following steps and phases. Specially consult: Policy overview and Brief tips for policy analysis in the field of bioeconomy",
				"to": "#"
			},
			"title": "Recommended resources"
		},
		"title": "Step 2: Analyse opportunities for improvement in the regional bioeconomy strategy and road map ",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
	}
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";

export default {
  name: "UpdatePhase3Step2",
  components: {
    ReadMore
  },
  data() {
    return {
      keyPerformanceFile: require("@/assets/files/1_3_2_1_Key_performance_indicators_to_characterize_the_regional_bioeconomy.docx")
        .default,
      bestPractisesFile: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
        .default,
      catalogueLink: "https://www.bio-based-solutions.eu/#/",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethodsTask1() {
      return Object.values(
        this.$t(
          "implementationRecommendations.task1.proposedMethods.unorderedList"
        )
      );
    },
    proposedMethodsTask2() {
      return Object.values(
        this.$t(
          "implementationRecommendations.task2.proposedMethods.unorderedList"
        )
      );
    },
    task2LeaderList() {
      return Object.values(
        this.$t("implementationRecommendations.task2.taskLeader.unorderedList")
      );
    },
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    },
    getSummaryChildren(obj) {
      return Object.values(obj);
    },
    getChildren(item) {
      return Object.values(item.children);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
